
import { defineComponent, ref, onMounted } from "vue";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
// import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { IPriceListDto } from "@/shared/service-proxies/generated-proxies";
import usePriceList from "@/composables/usePriceList";
import { PriceListFilter } from "@/models";
import AJLoader from "@/components/AjaxLoader.vue";
// import useProducts from "@/composables/useProducts";
import DeleteItemService from "@/core/services/DeleteItemService";
import { useStore } from "vuex";

export default defineComponent({
  name: "PriceList",
  components: {
    Datatable,
    AJLoader,
  },
  setup() {
    const { priceLists, getPriceLists, deletePriceList } = usePriceList();

    let loading = ref<boolean>(true);
    const showAdvanceFilter = ref<boolean>(false);

    const defaultFilter = ref({
      sorting: null,
      skipCount: 0,
      maxResultCount: 1000,
    } as unknown as PriceListFilter);

    const tableHeader = ref([
      {
        name: "Actions",
        key: "actions",
      },
      {
        name: "Name",
        key: "name",
        sortable: true,
      },
      {
        name: "Description",
        key: "description",
        sortable: true,
      },
    ]);

    const tableData = ref<Array<IPriceListDto>>([]);

    const initialData = ref<Array<IPriceListDto>>([]);
    const breadcrumbs = {
      title: "Price Lists",
      breadcrumbs: ["Price Lists"],
    };

    const store = useStore();
    const config = store.getters.getConfigurations;
    const permissions = config.result.auth.grantedPermissions;
    onMounted(async () => {
      // setCurrentPageBreadcrumbs("Products", ["Products header info"]);

      await getData();
    });

    const deleteHandler = async (id: string) => {
      const res = await DeleteItemService.delete(id as string, deletePriceList);

      if (res) {
        console.log("deleting...");
        for (let i = 0; i < tableData.value.length; i++) {
          if (tableData.value[i].id === id) {
            tableData.value.splice(i, 1);
          }
        }
      }
    };

    const getData = async () => {
      loading.value = true;

      await getPriceLists(defaultFilter.value as unknown as PriceListFilter);

      tableData.value = priceLists.value;

      initialData.value.splice(0, tableData.value.length, ...tableData.value);

      loading.value = false;
    };

    const searchPriceList = async () => {
      await getData();
    };

    return {
      defaultFilter,
      loading,
      tableData,
      tableHeader,
      showAdvanceFilter,
      deleteHandler,
      searchPriceList,
      breadcrumbs,
      permissions,
    };
  },
});
